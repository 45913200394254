import {TypedSupabaseClient} from "~/.shared/supabase/TypedSupabaseClient"

export const handleAuthChanges = (supabase: TypedSupabaseClient) => {

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT') {
      // delete cookies on sign out
      const expires = new Date(0).toUTCString()
      document.cookie = `access-token=; path=/; expires=${expires}; SameSite=Lax; secure`
      document.cookie = `refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`
    } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
      const maxAge = 100 * 365 * 24 * 60 * 60 // 100 years, never expires
      document.cookie = `access-token=${session?.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
      document.cookie = `refresh-token=${session?.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
    }
  })
}