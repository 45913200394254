import {useNavigation} from "react-router"

export const PageLoader = () => {

  const {state} = useNavigation()
  const isLoading = state === 'loading' || state === 'submitting'

  if (!isLoading) return null

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-1 bg-gray-50 animate-elastica">
      <div className="w-full h-full bg-gray-500"/>
    </div>
  )
}